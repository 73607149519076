<template>
  <div class="login-content">
    <div class="login-form">
      <div class="form-content">
        <div class="title-content">
          <img
            style="width: 200px;height: 50px"
            src="../../assets/images/logo_hisense.png"
            alt=""
          />
          <h3 class="title">海信商用冷链资产管理系统</h3>
        </div>
        <el-tabs v-model="loginType" @tab-click="handleClick">
          <el-tab-pane label="账号密码登录" name="account">
            <el-form
              label-width="80px"
              :rules="loginRules"
              :model="loginForm"
              label-position="top"
            >
              <el-form-item label="登陆账号" prop="userName">
                <el-input
                  v-model="loginForm.userName"
                  @keydown.enter.native="logins"
                  ref="userName"
                  maxlength="15"
                  placeholder="请输入账号名称"
                  name="userName"
                  type="text"
                  tabindex="1"
                  auto-complete="on"
                ></el-input>
              </el-form-item>
              <el-form-item label="请输入密码" prop="password">
                <el-input
                  @keydown.enter.native="logins"
                  type="password"
                  v-model="loginForm.password"
                  ref="password"
                  maxlength="16"
                  placeholder="请输入密码"
                  name="password"
                  tabindex="2"
                  auto-complete="on"
                  class="login-password"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            label="手机验证码登录"
            name="phone"
            :rel="phoneLoginRules"
          >
            <el-form
              label-width="80px"
              :model="phoneLoginForm"
              ref="form"
              :rules="phoneLoginRules"
              label-position="top"
            >
              <el-form-item label="登录手机号码" prop="phoneNumber">
                <el-input
                  v-model="phoneLoginForm.phoneNumber"
                  ref="phoneNumber"
                  maxlength="11"
                  placeholder="请输入手机号码"
                  name="userName"
                  type="text"
                  tabindex="1"
                  auto-complete="on"
                ></el-input>
              </el-form-item>
              <el-form-item label="短信验证码" prop="verificationCode">
                <el-row>
                  <el-col :span="17">
                    <el-input
                      v-model="phoneLoginForm.verificationCode"
                      ref="password"
                      maxlength="6"
                      placeholder="请输入短信验证码"
                      tabindex="2"
                      auto-complete="on"
                      @keydown.enter.native="logins"
                    ></el-input>
                  </el-col>
                  <el-col :span="7">
                    <el-button
                      :disabled="isCode"
                      @click="countDown('get')"
                      class="login_button"
                      type="primary"
                      ><span>{{ verificationText }}</span></el-button
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <router-link to="resetPassword"
          ><el-link class="login-forget" type="primary" :underline="false"
            >忘记密码</el-link
          ></router-link
        >
        <el-button @click="logins" type="primary" class="app-login-button"
          >登录</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  login,
  getAuthCode,
  getmssage,
  getRsaPublicKey
} from "../../api/login";
import { encrypt } from "../../utils/func";
export default {
  name: "index",
  data() {
    const mobile = (rule, value, callback) => {
      if (value && !/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    const validateuseName = (rule, value, callback) => {
      if (value.length <= 0) {
        callback(new Error("请输入用登录账号"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能少于6位"));
      } else {
        callback();
      }
    };
    return {
      verificationText: "获取验证码",
      totalTime: 60,
      isCode: false,
      loginType: "account",
      loginForm: {
        userName: "",
        password: ""
      },
      type: "",
      loginRules: {
        userName: [
          { required: true, validator: validateuseName, trigger: "blur" }
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword }
        ]
      },
      phoneLoginRules: {
        phoneNumber: [
          { required: true, trigger: "blur", message: "请输入手机号" },
          {
            validator: (rule, value, callback) => {
              if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正确的手机号!"));
              }
            },
            trigger: "change"
          }
        ],
        verificationCode: [
          // { required: true, trigger: "blur", message: "请输入短信验证码" }
        ]
        // randomrediskey_: [
        //   { required: true, trigger: 'blur', message: '请输入图形验证码' }
        // ]
      },
      phoneLoginForm: {
        phoneNumber: "",
        verificationCode: "",
        randomrediskey_: ""
      }
    };
  },
  // created() {
  //   //监听键盘
  //   let that = this;
  //   document.onkeypress = function(e) {
  //     var keycode = document.all ? event.keyCode : e.which;
  //     if (keycode == 13) {
  //       that.logins(); // 登录方法名
  //       return false;
  //     }
  //   };
  // },
  methods: {
    //判断登录类型
    handleClick(tab) {
      this.loginType = tab.name;
    },
    // 获取短信倒计时
    // 获取短信倒计时
    countDown(type) {
      if (type) {
        this.type = type;
      } else {
        this.type = "";
      }
      const _this = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.phoneLoginForm.phoneNumber) {
            this.getCode();
            _this.isCode = true;
            this.verificationText = _this.totalTime + "s后重试";
            const setInter = setInterval(function() {
              _this.totalTime--;
              _this.verificationText = _this.totalTime + "s后重试";
              if (_this.totalTime < 0) {
                clearInterval(setInter);
                _this.verificationText = "获取验证码";
                _this.totalTime = 60;
                _this.isCode = false;
              }
            }, 1000);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取验证码
    getCode() {
      getAuthCode({ phone: this.phoneLoginForm.phoneNumber }).then(res => {
        console.log(res);
      });
    },
    getRsaPublicKey(callback) {
      getRsaPublicKey({}).then(res => {
        if (res.data.resultCode == 0) {
          callback(res.data.data);
        } else {
          this.$message({
            showClose: true,
            message: "获取加密信息错误",
            type: "error"
          });
        }
      });
    },

    //登录
    logins() {
      // localStorage.removeItem('token')
      // localStorage.setItem("token","123")
      let data = {
        account: "",
        loginType: "",
        number: "",
        password: "",
        ver: ""
      };
      if (this.loginType == "phone") {
        data.account = "";
        data.loginType = 1;
        data.number = this.phoneLoginForm.phoneNumber;
        data.password = "";
        data.ver = this.phoneLoginForm.verificationCode;
        login(data).then(res => {
          console.log(res, "res");
          if (res.data.resultCode == 1) {
            this.$message({
              showClose: true,
              message: "用户名或密码错误",
              type: "error"
            });
          } else {
            this.$message({
              showClose: true,
              message: "登录成功！",
              type: "success"
            });
            // let token =res.data.data.token;
            if (res.data.data.fnList.length)
              res.data.data.fnList.sort((a, b) => {
                return a.fnId - b.fnId;
              });
            let fn = JSON.stringify(res.data.data.fnList);
            localStorage.setItem("fns", fn);
            var objAfter = JSON.parse(localStorage.getItem("fns"));
            for (let i = 0; i < objAfter.length; i++) {
              console.log(objAfter[i]);
            }

            localStorage.setItem("token", res.data.data.token);
            localStorage.setItem(
              "coldChainAMHead",
              res.data.data.coldChainAMHead
            );
            localStorage.setItem("deptId", res.data.data.deptId);
            localStorage.setItem("userId", res.data.data.userId);
            localStorage.setItem("userName", res.data.data.userName);
            // localStorage.setItem("userId",res.data.data.token)
            getmssage({}).then(res => {
              console.log(res, "==========");
            });
            this.$router.push("/dashboard/index");
          }
        });
      } else {
        data.account = this.loginForm.userName;
        data.loginType = 0;
        data.number = "";

        data.ver = "";
        this.getRsaPublicKey(key => {
          data.str = encrypt(this.loginForm.password, key);
          login(data).then(res => {
            if (res.data.resultCode == 1) {
              this.$message({
                showClose: true,
                message: "用户名或密码错误",
                type: "error"
              });
            } else {
              this.$message({
                showClose: true,
                message: "登录成功！",
                type: "success"
              });
              // for (let i = 0;i<fn.length;i++){
              //     // if()
              // }
              if (res.data.data.fnList.length)
                res.data.data.fnList.sort((a, b) => {
                  return a.fnId - b.fnId;
                });
              let fn = JSON.stringify(res.data.data.fnList);
              localStorage.setItem("fns", fn);
              localStorage.setItem("token", res.data.data.token);
              console.log(res.data.data);
              localStorage.setItem(
                "coldChainAMHead",
                res.data.data.coldChainAMHead
              );
              localStorage.setItem("deptId", res.data.data.deptId);
              localStorage.setItem("userId", res.data.data.userId);
              localStorage.setItem("userName", res.data.data.userName);
              getmssage({}).then(res => {
                localStorage.setItem("messageNum", res.data.data);

                console.log(res, "==========");
              });
              this.$router.push("/dashboard/index");
            }
          });
        });

        // phoneLogin(data).then(res =>{
        //     console.log(res)
        // })
      }
    }
  }
};
</script>

<style scoped>
/deep/ .el-tabs__content {
  height: 220px !important;
}
.login-content {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/login_bg.jpg") center/cover no-repeat;
}
/deep/ .el-tabs__nav-wrap::after {
  background: #fff;
}
.login-form {
  position: relative;
  margin: auto;
  width: 540px;
  max-width: 100%;
  top: 20vh;
  background: #fff;
}
.form-content {
  padding: 40px;
}
.title {
  margin: 0 0 20px;
  font-size: 28px;
  line-height: 40px;
  color: #333333;
  font-weight: normal;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding-bottom: 0 !important;
}
.login-forget {
  font-size: 13px;
  float: left;
}
.app-login-button {
  width: 100%;
  height: 46px;
  font-size: 16px;
  margin-top: 26px;
  background-image: linear-gradient(-115deg, #22b7f4, #02cccc);
  box-shadow: 0 4px 8px 0 #b0f2f7;
  &:hover {
    opacity: 0.8;
  }
}
.login_button {
  margin-left: 10px;
}
</style>
