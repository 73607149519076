<template>
  <div>
    <p class="title">冷链资产管理系统</p>
    <p class="name">忘记密码</p>
    <div class="form_content">
      <el-form
        class="form"
        label-position="top"
        label-width="80px"
        :model="changeInformation"
        v-show="Step1"
        ref="form"
        :rules="rules"
      >
        <el-form-item label="手机号码" prop="phoneNumber">
          <el-input v-model="changeInformation.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item
          style="margin-top: 10px"
          prop="verifyCode"
          label="短息验证码"
        >
          <el-input
            class="getCode_input"
            v-model="changeInformation.verifyCode"
          ></el-input>
          <el-button
            :disabled="isCode"
            class="getCode_button"
            @click="getAuthCodes"
            >{{ verificationText }}</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button class="next_button" @click="next">下一步</el-button>
        </el-form-item>
      </el-form>
      <el-form
        v-show="Step2"
        class="form"
        label-position="top"
        label-width="80px"
        :model="changeInformation"
        ref="formData"
        :rules="rules"
      >
        <el-form-item>
          <p class="sendNumber" v-show="Step2">
            短信将发送至:{{ changeInformation.phoneNumber }}
          </p>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="changeInformation.newPassword"></el-input>
        </el-form-item>
        <el-form-item
          style="margin-top: 10px"
          prop="affirmPassword"
          label="确认密码"
        >
          <el-input v-model="changeInformation.affirmPassword"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="next_button" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-form
        v-show="Step3"
        class="form"
        label-position="top"
        label-width="80px"
      >
        <el-form-item style="margin-top: 10px">
          <p>密码修改成功，请前往登录</p>
        </el-form-item>
        <el-form-item>
          <el-button class="next_button" @click="toLink('/login')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getAuthCode, changePassword, login } from "@/api/login.js";
export default {
  name: "resetPassword",
  data() {
    return {
      Step1: true,
      Step2: false,
      Step3: false,
      changeInformation: {
        phoneNumber: "",
        verifyCode: "",
        newPassword: "",
        affirmPassword: ""
      },
      verificationText: "获取验证码",
      totalTime: 60,
      isCode: false,
      rules: {
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (/^[1][3,4,5,7,8][0-9]{9}$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正确的手机号"));
              }
            },
            trigger: "blur"
          }
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              callback();
            },
            trigger: "blur"
          }
        ],
        affirmPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value === this.changeInformation.newPassword) {
                callback();
              } else {
                callback(new Error("两次密码不一致"));
              }
            },
            trigger: "change"
          }
        ],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    //修改密码
    handleReset() {
      this.$refs["formData"].validate(valid => {
        if (valid) {
          const config = {
            password: this.changeInformation.newPassword,
            number: this.changeInformation.phoneNumber
          };
          changePassword(config).then(({ data }) => {
            if (data.resultCode === 0) {
              this.Step2 = false;
              this.Step3 = true;
            } else {
              this.$message({
                showClose: true,
                message: "重置失败",
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //下一步
    next() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const data = {
            account: "",
            loginType: 1,
            number: this.changeInformation.phoneNumber,
            password: "",
            ver: this.changeInformation.verifyCode
          };

          login(data).then(res => {
            if (res.data.resultCode === 0) {
              this.Step1 = false;
              this.Step2 = true;
              localStorage.setItem(
                "coldChainAMHead",
                res.data.data.coldChainAMHead
              );
            } else {
              this.$message.error(res.data.errorDesc);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toLink(path) {
      this.$router.push({ path: path });
    },
    //获取短信验证码
    getAuthCodes() {
      this.countDown();
      getAuthCode({ phone: this.changeInformation.phoneNumber }).then(res => {
        console.log(res);
      });
    },
    // 获取短信倒计时
    countDown() {
      const _this = this;
      if (this.changeInformation.phoneNumber != "") {
        _this.isCode = true;
        this.verificationText = _this.totalTime + "s后重试";
        const setInter = setInterval(function() {
          _this.totalTime--;
          _this.verificationText = _this.totalTime + "s后重试";
          if (_this.totalTime < 0) {
            clearInterval(setInter);
            _this.verificationText = "获取验证码";
            _this.totalTime = 60;
            _this.isCode = false;
          }
        }, 1000);
      }
    }
  }
};
</script>

<style scoped>
.sendNumber {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #3a3a3a;
  text-align: inherit;
  /*margin-left: 330px;*/
  letter-spacing: -0.04px;
}
.next_button {
  margin-top: 50px;
  width: 100%;
  background-image: linear-gradient(115deg, #02cccc 0%, #22b7f4 73%);
  box-shadow: 0 4px 8px 0 #b0f2f7;
  border-radius: 4px;
  border-radius: 4px;
  color: #fff;
}
.getCode_input {
  width: 260px;
}
.getCode_button {
  width: 110px;
  margin-left: 10px;
  background: #00cccc;
  color: #fff;
}
.getCode_button:hover {
  width: 110px;
  margin-left: 10px;
  background: #00cccc;
  color: #fff;
}
.form {
  width: 380px;
}
.title {
  font-family: PingFangSC-Regular;
  font-size: 28px;
  color: #333333;
  letter-spacing: -0.08px;
  text-align: center;
  margin-top: 200px;
}
.name {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #858585;
  letter-spacing: -0.05px;
  text-align: center;
  margin-top: 10px;
}
.form_content {
  display: flex;
  justify-content: center;
}
</style>
