import axios from "../utils/request";

export function getAuthCode(data) {
  return axios({
    url: "/clodchainAM/sys/ver",
    method: "get",
    params: data
  });
}

export function login(data) {
  return axios({
    url: "/clodchainAM/sys/login",
    method: "post",
    data: data
  });
}
export function getRsaPublicKey(params) {
  return axios({
    url: "/clodchainAM/sys/get_rsa_public_key",
    method: "get",
    params
  });
}
//修改密码
export function changePassword(data) {
  return axios({
    url: "/clodchainAM/web/reSet",
    method: "post",
    data: data
  });
}

//获取消息条数
export function getmssage(data) {
  return axios({
    url: "/clodchainAM/web/message/messageTotal",
    method: "get",
    params: data
  });
}
